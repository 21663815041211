

.c-settings-page__form-section-title {
  font-weight: 600;
  font-size: 0.875rem;
}

.c-settings-page__form-field {
  width: 100%;
}

.c-settings-page__color-picker .c-input-field__inner {
  justify-content: flex-end;
}