.c-projects-table__projects-cell {
  align-items: center;
  display: flex;
  gap: var(--g-spacing-small);
}

.c-projects-table__new-window-icon {
  height: var(--g-spacing);
  width: var(--g-spacing-6x-large);
  position: relative;
  top: var(--g-spacing-3x-small);
}
