/* stylelint-disable selector-class-pattern */
ul li,
ol li {
  margin-left: 0;
}

.c-sidebar__container--left {
  padding-bottom: var(--g-spacing-6x-large);
}

@media (min-width: 28em) {
  .c-sidebar-nav__page-wrapper.is-pushed-right {
    margin-left: calc(var(--c-left-sidebar-max-width) - calc(var(--g-spacing)* 8));
  }
}

.c-table-page-template .c-page-container > .l-container,
.c-basic-page-layout .c-page-container > .l-container,
.c-card-list-page-template .c-page-container > .l-container {
  display: block;
}

.c-footer {
  z-index: 2;
}

.c-page-toggle .c-toggle-switch__slider::before {
  left: 0;
}


.c-table-page-template .c-toggle-switch__wrapper--inline .c-label__text,
.c-basic-page-layout .c-toggle-switch__wrapper--inline .c-label__text,
.c-card-list-page-template .c-toggle-switch__wrapper--inline .c-label__text {
  white-space: nowrap;
}

.c-tablist__nav {
  padding: 0 var(--g-spacing-2x-large) 0 var(--g-spacing-small);
}

.c-dashboard-organisation-controller {
  background-color: var(--g-color-white);
}

.c-page-container {
  max-width: 125rem;
  margin: 0 auto;
}

.c-basic-page-layout__info-box {
  background-color: var(--g-color-white);
}

.c-time-bound-chart-container .c-chart-grid {
  grid-auto-rows: minmax(26.5rem, auto);
}

.c-time-bound-chart-container .c-chart-picker .c-card {
  min-height: 26.5rem;
}
