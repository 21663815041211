.c-container {
  padding: 1rem 4rem;
  width: 100%;
  min-height: 100vh;
}

.c-container--xs {
  max-width: 48rem;
}

.c-contact-form-container {
  max-width: 45rem;
  margin: auto;
}

.c-container--large {
  max-width: 100rem;
}

.c-container--transition {
  transition: var(--g-transition-time) ease-in-out;
}

.c-page-layout-template__main {
  position: relative;
  flex: 1;
}

@media screen and (min-width: 125em) {
  .c-container {
    width: 90%;
  }

  .c-container--small {
    max-width: 100rem;
  }
}
