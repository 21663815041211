.c-form__field {
  padding-bottom: var(--g-spacing);
}

.c-form__footer {
  display: flex;
  justify-content: center;
  padding-top: var(--g-spacing);
}

@media screen and (min-width: 48em) {
  .c-form__footer {
    justify-content: flex-end;
  }
}
