.trend-card-header-container {
  justify-content: space-between;
  min-height: 6rem;
}

.trend-card-header-container,
.trend-card-header-container > div {
  display: flex;
  gap: 10px;
}

.trend-card-header-container > div > svg {
  margin-top: 5px;
}

.trend-card-header-container > div > div > p {
  margin: auto !important;
}

.icon-trend-card {
  width: 20px;
  height: 20px;
}

.trend-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 10px;
}

.trend-container > svg {
  width: 13px;
  height: 13px;
}
.trend {
  font: normal normal bold 12px/22px Calibri;
}
.trend-container:has(> .trend.negative) > svg {
  color: var(--red-400);
  transform: scaleX(-1);
}
.trend-container:has(> .trend.positive) > svg {
  color: var(--green);
}
.trend.negative {
  color: var(--red-400);
}

.trend.positive {
  color: var(--green);
}

.trend-card {
  min-width: 21.25rem;
  padding: 0.6rem;
  height: 10rem;
}

.trend-card-link {
  height: 45px;
  color: var(--color-primary);
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.entity-text {
  font-weight: bold;
}

.entity-count {
  font-weight: bold;
  font-size: 24px;
}

.separator {
  border-top: 1px solid #eff0f2;
  width: 200%;
  margin-left: -50%;
}

.trend-card-link > .icon {
  transform: rotate(270deg) !important;
  border-radius: 50%;
  display: flex;
  border: 1px solid var(--grey-100);
  padding: 0.3rem;
  transition: 0.2s;
}

.trend-card-link:hover > .icon {
  background: var(--color-primary);
  border: 1px solid var(--color-primary) !important;
}

.trend-card-link:hover > .icon > svg {
  color: white;
}

.trend-card-link > .icon > svg {
  width: 10px;
  height: 10px;
  color: black;
}

.count-skeleton {
  width: 9rem;
}
